import React, { createContext, useCallback, useContext, useState } from 'react'
import { initialAlertState } from './ContextHelpers'

const errors = {
  500: {
    message: 'Sorry, something went wrong.',
    forceLogout: false
  },
  401: {
    message: 'Your session has expired, please click here to login again: ',
    forceLogout: true
  },
  403: {
    message: 'Sorry, you cannot perform that action at this time.',
    forceLogout: false
  },
  400: {
    message: 'Sorry, something went wrong.',
    forceLogout: false
  }
}

const ToastContext = createContext()

export const ToastProvider = ({ children }) => {
  const [alert, setAlert] = useState(initialAlertState)

  const updateAlert = useCallback(({ status, message, title, forceLogout }) => {
    if (status === 422 && message?.errors) {
      message = typeof message.errors !== 'string'
        ? Object.values(message.errors)[0].join(', ')
        : message.errors
    }
    if (Object.keys(errors).includes(status.toString())) {
      message = errors[status].message
    }
    forceLogout = errors[status]?.forceLogout ? errors[status]?.forceLogout : forceLogout
    return setAlert({ status, title, message, forceLogout })
  }, [setAlert])

  const resetAlert = useCallback(() => {
    setAlert(initialAlertState)
  }, [])

  return (
    <ToastContext.Provider value={{
      alert,
      setAlert: updateAlert,
      resetAlert
    }}>
      {children}
    </ToastContext.Provider>
  )
}

export const useToastAlert = () => {
  const context = useContext(ToastContext)
  if (!context) {
    throw new Error('useData must be used within a DataProvider - toast context')
  }
  return context
}
