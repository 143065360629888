import React, { useEffect, useState } from 'react'
import Camera from 'react-html5-camera-photo'
import classNames from 'classnames'
import { useIsMobile } from 'src/hooks/useMediaQuery'
import { useGlobalContext } from 'src/stores/GlobalContext'
import { useToastAlert } from 'src/stores/ToastContext'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import Loader from 'src/components/Elements/Loader'
import ImageEditor from './ImageEditor'
import settingStyles from '../settingStyles.module.scss'
import 'react-html5-camera-photo/build/css/index.css'

const CameraUploader = () => {
  const { currentUser } = useGlobalContext()
  const { setAlert } = useToastAlert()
  const isMobile = useIsMobile()
  const [image, setImage] = useState(null)
  const [cameraOn, setCameraOn] = useState(false)

  useEffect(() => {
    setCameraOn(true)
  }, [])

  const takePhoto = (uri) => {
    setImage(uri)
    setCameraOn(false)
  }

  return (
    <div
      className={classNames(
        generalStyles.flexColumn,
        generalStyles.alignVertical
      )}
    >
      {cameraOn
        ? <>
          <div className={classNames(settingStyles.cameraContainer, isMobile && settingStyles.cameraContainerMobile)}>
            <div className={settingStyles.loaderContainer}>
              <Loader />
            </div>
            <div className={settingStyles.camera}>
              <Camera
                onTakePhoto={(dataUri) => {
                  takePhoto(dataUri)
                }}
                onCameraError={(error) => {
                  setAlert({
                    status: 500,
                    message: error,
                    title: 'Error',
                    forceLogout: false
                  })
                }}
                idealResolution={{ width: 640, height: 480 }}
                imageCompression={0.97}
                isImageMirror={false}
                isSilentMode={false}
                isDisplayStartCameraError={true}
                isFullscreen={false}
                sizeFactor={1}
              />
            </div>
          </div>
        </>
        : <ImageEditor selectedImage={image} currentUserId={currentUser?.id} />
      }
    </div>
  )
}

export default CameraUploader
