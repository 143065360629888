import React from 'react'
import { useIsMobile } from 'src/hooks/useMediaQuery'
import classNames from 'classnames'
import IcomoonReact from 'icomoon-react'
import PropTypes from 'prop-types'
import iconSet from 'src/assets/icons/iconList.json'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import variables from 'src/assets/styles/variables.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import elementStyles from './elements.module.scss'
import layoutStyles from 'src/components/Layout/layouts.module.scss'
import HorizontalLine from './HorizontalLine'

const TypeProps = {
  title: PropTypes.string,
  closeModal: PropTypes.func.isRequired
}

const AlertModal = ({ title, closeModal, children }) => {
  const isMobile = useIsMobile()
  return (
    <div className={layoutStyles.alertModalOverlayContainer}>
      <div className={classNames(elementStyles.alertContainer, isMobile && elementStyles.alertContainerMobile)}>
        <div className={elementStyles.alertBody}>
          <div className={elementStyles.closeContainer}>
            <button
              className={classNames(
                elementStyles.iconContainer,
                elementStyles.closeButton
              )}
              onClick={closeModal}
            >
              <IcomoonReact
                iconSet={iconSet}
                color={variables.darkGrey}
                size={12}
                icon="Control-CrossIcon-Large-GreyOutline"
              />
            </button>
          </div>
          <div className={elementStyles.alertHeaderContainer}>
            <div className={elementStyles.alertText}>
              <span className={fontStyles.bodybolddark}>{title} </span>
            </div>
            <div className={generalStyles.mt20} />
            <HorizontalLine color='lightGrey' fullWidth />
          </div>
          <div className={classNames(elementStyles.alertContentContainer)}>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

AlertModal.propTypes = {
  ...TypeProps
}

export default AlertModal
