import React, { useState } from 'react'
import classNames from 'classnames'
import { useIsMobile } from 'src/hooks/useMediaQuery'
import { useGlobalContext } from 'src/stores/GlobalContext'
import { useToastAlert } from 'src/stores/ToastContext'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import UserImage from 'src/components/Elements/UserImage'
import ImageEditor from './ImageEditor'
import settingStyles from '../settingStyles.module.scss'

const ImageUploader = () => {
  const { currentUser } = useGlobalContext()
  const { setAlert } = useToastAlert()
  const [image, setImage] = useState(currentUser?.photo)
  const [preview, setPreview] = useState(true)
  const isMobile = useIsMobile()

  const handleNewImage = async (e) => {
    e.preventDefault()
    if (e.target.files[0]) {
      const fileType = e.target.files[0].type
      const validImageTypes = ['image/jpeg', 'image/png']
      if (validImageTypes.indexOf(fileType) < 0) {
        setAlert({
          status: 0,
          message: 'Profile image must be in .jpg, .jpeg, or .png format',
          title: 'Error',
          forceLogout: false
        })
        return false
      } else {
        setImage(e.target.files[0])
        setPreview(false)
      }
    }
  }

  return (
    <div
      className={classNames(
        generalStyles.flexColumn,
        generalStyles.alignVertical
      )}
    >
      {preview
        ? <>
          <UserImage
            photoUrl={image}
            styles={classNames(settingStyles.previewImage, isMobile && settingStyles.previewImageMobile)}
            altText={`${currentUser?.first_name} ${currentUser?.last_name}`}
          />
          <div className={generalStyles.mt40}>
            <label
              htmlFor="file-upload"
              className={settingStyles.imageSelector}
            >
              Choose file
            </label>
            <input
              id="file-upload"
              type="file"
              onChange={(e) => handleNewImage(e)}
            />
          </div>
        </>
        : <ImageEditor selectedImage={image} currentUserId={currentUser?.id} />
      }
    </div>
  )
}

export default ImageUploader
