import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import useFetchCurrentUser from 'src/queries/currentUser/useFetchCurrentUser'
import useFetchEvent from 'src/queries/events/useFetchEvent'
import useFetchAttendees from 'src/queries/attendees/useFetchAttendees'
import useFetchSessions from 'src/queries/sessions/useFetchSessions'
import { initialSidePanel } from './ContextHelpers'

const GlobalContext = createContext()

export const Provider = ({ children }) => {
  const location = useLocation()
  const { eventId, attendeeId, partnerId, evalId, sessionId } = useParams()
  const { currentUser } = useFetchCurrentUser()
  const { currentEvent, isLoadingEvent } = useFetchEvent()
  const { attendees, filteredAttendees, isLoadingAttendees } = useFetchAttendees()
  const { sessions, isLoadingSessions } = useFetchSessions()

  const [sidePanelDetails, setSidePanelDetails] = useState(initialSidePanel)
  const [currentEventBizCardAccess, setCurrentEventBizCardAccess] = useState(false)

  const attendee = useMemo(() => isEmpty(attendees)
    ? {}
    : attendees.find(person => person.user_id === parseInt(attendeeId || currentUser?.id)),
  [attendeeId, attendees])

  useEffect(() => {
    // we want to make sure we reset the permissions everytime we switch events
    setCurrentEventBizCardAccess(false)
  }, [eventId])

  useEffect(() => {
    // if url has a session id, we want to populate the side panel with the session data
    if (sessionId) {
      setSidePanelDetails({
        type: 'agenda_detail',
        data: Number(sessionId)
      })
    } else {
      const messagesPath = location.pathname.includes('messages')
      if (!messagesPath) setSidePanelDetails(initialSidePanel)
    }
  }, [sessionId, location])

  return (
    <GlobalContext.Provider value={{
      eventId,
      attendeeId,
      partnerId,
      evalId,
      sessionId,
      currentUser,
      currentEvent,
      filteredAttendees,
      attendees,
      attendee,
      sidePanelDetails,
      sessions,
      currentEventBizCardAccess,
      isLoadingEvent,
      isLoadingSessions,
      isLoadingAttendees,
      updateCurrentEventBizCardAccess: () => setCurrentEventBizCardAccess(true),
      setSidePanelDetails: (data) => setSidePanelDetails({ ...data }),
      resetSidePanelDetails: () => setSidePanelDetails(initialSidePanel)
    }}>
      {children}
    </GlobalContext.Provider>
  )
}

export const useGlobalContext = () => {
  const context = useContext(GlobalContext)
  if (!context) {
    throw new Error('useData must be used within a DataProvider')
  }
  return context
}
