import React, { useCallback, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import Input from 'src/components/Elements/Input'
import Button from 'src/components/Elements/Button'
import { useForm, Controller } from 'react-hook-form'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import classNames from 'classnames'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import Loader from 'src/components/Elements/Loader'
import preloginStyles from './prelogin.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import useLogin from 'src/queries/auth/useLogin'

const SignInForm = () => {
  const { mutateAsync, isLoading } = useLogin()
  const [data, setData] = useState(null)
  const [loginError, setLoginError] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const {
    // reset, for clearing cookie?
    handleSubmit,
    control,
    formState: { errors, isValid },
    watch
  } = useForm({
    defaultValues: {
      email: Cookies.get('email') || '',
      password: ''
    }
  })

  // checks if loading keeps happening after half a minute which means there's a network error
  useEffect(() => {
    let isLoadingTimer

    // eslint-disable-next-line prefer-const
    isLoadingTimer = setTimeout(() => {
      if (isLoading) {
        setLoginError('Network Error')
      }
    }, 3000)

    return () => {
      clearTimeout(isLoadingTimer)
    }
  }, [isLoading])

  const login = async (data) => {
    const response = await mutateAsync({ email: data.email, password: data.password })
    if (response.status < 200 || response.status > 300) {
      const errorMessage = typeof response.data !== 'string'
        ? Object.values(response.data)[0].join(', ')
        : response.data
      if (!isEmpty(errorMessage)) {
        setLoginError(errorMessage === 'account_disabled'
          ? 'This account is no longer active. Contact <a href="https://my.evanta.com/auth/contact">support</a> to reactivate your account.'
          : 'Invalid login credentials'
        )
      }
    }
  }

  const handleKeyDown = useCallback((e) => {
    if (e.code === 'Enter') {
      login(data)
    }
  }, [data, login])

  const IconComponent = showPassword ? FaEye : FaEyeSlash

  return (
    <div className={preloginStyles.formContainer} data-testid="preloginForm">
      <div>
        <Controller
          name="email"
          control={control}
          rules={{
            required: {
              value: true
            }
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              id="email"
              label={{ label: 'Email', placeholder: 'Email' }}
              name="email"
              type="email"
              icon={{ name: 'Symbol-EmailIcon-Large-GreySolid', size: 27 }}
              onBlur={onBlur}
              value={value}
              onChange={(text) => {
                onChange(text)
                setData({ ...watch() })
              }}
            />
          )}
        />
        <div className={preloginStyles.inputGap} />
        <Controller
          name="password"
          control={control}
          rules={{
            required: {
              value: true
            }
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <div className={classNames(generalStyles.flexRow)}>
              <Input
                id="password"
                name="password"
                label={{ label: 'Password', placeholder: 'Password' }}
                type={showPassword ? 'text' : 'password'}
                icon={{ name: 'Symbol-PasswordIcon-GreySolid', size: 27 }}
                onBlur={onBlur}
                value={value}
                fullWidth
                onChange={(text) => {
                  onChange(text)
                  setData({ ...watch() })
                }}
                onKeyDown={handleKeyDown}
                error={
                  JSON.stringify(errors) !== '{}' || loginError
                    ? loginError || 'Invalid login credentials'
                    : null
                }
              />
              <IconComponent style={{ marginTop: 15 }} onClick={() => setShowPassword(!showPassword)} />
            </div>
          )}
        />
        <div className={generalStyles.mt20}>
          <Link to="/auth/forgot-password" data-testid="forgotPasswordLink">Forgot Password?</Link>
        </div>
      </div>
      <div className={preloginStyles.btnContainer}>
        { isLoading && loginError !== 'Network Error'
          ? <Loader />
          : <Button
            color={isValid ? 'evantaBlue' : 'evantaBlueOutline'}
            size="large"
            onClick={handleSubmit(login)}
            label="SIGN IN"
            disabled={!isValid}
            id="submitLogin"
          />
        }
      </div>
      <div className={preloginStyles.actionTextRow}>
        <span
          className={classNames(
            fontStyles.h6variant2demigrey,
            preloginStyles.notMember
          )}
        >
          Not a Member? <wbr />
          <a
            href="https://www.evanta.com/apply#apply"
            target="_blank"
            rel="noreferrer"
            className={fontStyles.h6variant2demibluecentered}
          >
            Apply to Join
          </a>
        </span>
        <Link to="/auth/help" className={fontStyles.h6variant2demibluecentered}>
          Help & Support
        </Link>
      </div>
    </div>
  )
}

export default SignInForm
