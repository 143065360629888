import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIsMobile } from 'src/hooks/useMediaQuery'
import { isEmpty } from 'lodash'
import IcomoonReact from 'icomoon-react'
import classNames from 'classnames'
import { useGlobalContext } from 'src/stores/GlobalContext'
import AgendaItemCard from 'src/features/programs/program/agenda/components/AgendaItemCard'
import ItinerarySessionCard from 'src/features/programs/program/agenda/components/ItinerarySessionCard'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import variables from 'src/assets/styles/variables.module.scss'
import iconSet from 'src/assets/icons/iconList.json'
import HorizontalLine from 'src/components/Elements/HorizontalLine'
import Loader from 'src/components/Elements/Loader'
import AgendaFilters from './components/AgendaFilters'
import agendaStyles from './agendaStyles.module.scss'

const AgendaList = ({ sessions }) => {
  const navigate = useNavigate()
  const {
    currentEvent,
    isLoadingEvent,
    sessionId,
    attendee,
    resetSidePanelDetails,
    setSidePanelDetails,
    isLoadingSessions
  } = useGlobalContext()
  const isMobile = useIsMobile()
  // same as in mobile, check for agenda availability if any sessions returned
  const agendaAvailable = sessions?.length > 0

  const initialFilters = (agendaActive = false) => {
    return {
      all: {
        filterName: 'Agenda',
        active: agendaActive
      },
      schedule: {
        filterName: 'On My Schedule',
        active: false
      }
    }
  }
  const [filters, setFilters] = useState({
    ...initialFilters(true)
  })
  const [filteredSessions, setFilteredSessions] = useState(sessions)
  const [sectionVisibility, setSectionVisibility] = useState({})

  const filterSessions = () => {
    if (filters.schedule?.active) {
      setFilteredSessions(
        sessions.filter((s) => {
          return s.registration_status === 'un_register' || s.itinerary
        })
      )
    }
    return filteredSessions
  }

  useEffect(() => {
    setFilteredSessions(sessions)
    filterSessions()
    setSectionVisibility(
      sessions
        .map((item) => item.formatted_display_date)
        .filter((value, index, self) => self.indexOf(value) === index)
        .reduce((a, v, i) => ({ ...a, [`section_${i}`]: true }), {})
    )
  }, [filters, currentEvent.id, sessions])

  const handleScrollToItem = useCallback(() => {
    const el = document.getElementById(`session-${sessionId}`)
    // scroll into view if not null
    el?.focus()
    if (el !== null) {
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      })
    }
  }, [])

  useEffect(() => {
    // put this in own useeffect so isn't running every time sessionId changes in Url params and scrolling
    if (sessionId && !isLoadingSessions) {
      setTimeout(() => {
        handleScrollToItem()
      }, 300)
    }
  }, [isLoadingSessions])

  useEffect(() => {
    // kept little delay for scrolling otherwise looks jumpy
    // only scroll to time if on agenda, sessionId undefined, user is register in the event and attended
    if (sessionId === undefined && !isEmpty(attendee) && sessions.length && attendee.is_attended) {
      setTimeout(() => {
        handleScrollToTime()
      }, 500)
    }
  }, [attendee, sessions])

  const handleClick = useCallback((sessionId) => {
    navigate(`/programs/${currentEvent.id}/agenda/${sessionId}`)
    setSidePanelDetails({
      type: 'agenda_detail',
      data: sessionId
    })
  }, [navigate, setSidePanelDetails, currentEvent])

  const renderComingSoon = () => {
    return (
      <div data-testid="agendaSoon" className={generalStyles.pt40}>
        <div dangerouslySetInnerHTML={{ __html: currentEvent.no_agenda_body }} />
      </div>
    )
  }

  const renderAgendaSections = () => {
    const agendaSections = []
    const sections = [
      ...new Map(
        filteredSessions.map((item) => [
          item.formatted_display_time + item.formatted_display_date,
          {
            title: item.formatted_display_time,
            day: item.formatted_display_date,
            epochStart: item.epoch_start_time
          }
        ])
      ).values()
    ].map(({ title, day, epochStart }, index) => {
      return {
        title,
        day,
        index,
        epochStart,
        data: filteredSessions.filter((s) => {
          return (
            title === s.formatted_display_time &&
            s.formatted_display_date === day
          )
        })
      }
    })
    // 0: {title: "18:00 - 21:00", day: "03 October 2022", index: 0, data: Array(1)}
    // 1: {title: "08:00 - 09:00", day: "04 October 2022", index: 1, data: Array(1)}
    const dataGroupedByDay = sections.reduce((acc, section) => {
      const key = section.day
      const curGroup = acc[key] ?? []

      return { ...acc, [key]: [...curGroup, section] }
    }, {})

    for (const day in dataGroupedByDay) {
      const sectionContent = []
      const indexOfDay = Object.keys(dataGroupedByDay).indexOf(day)
      if (currentEvent.event_agenda_status_key === 'event_agenda_status_full_agenda') {
        agendaSections.push(renderDayHeader(day, indexOfDay))
      }
      dataGroupedByDay[day].forEach((timeslot, i) => {
        if (currentEvent.event_agenda_status_key === 'event_agenda_status_full_agenda') {
          sectionContent.push(
            renderTimeSectionHeader(timeslot?.title, timeslot?.epochStart, i)
          )
        }
        timeslot.data.forEach((agendaItem, index, timeslot) => {
          sectionContent.push(renderAgendaItem(agendaItem, index, timeslot, i))
        })
      })
      if (sectionVisibility[`section_${indexOfDay}`]) {
        agendaSections.push(
          <div key={`section-content-${indexOfDay}`} data-testid={`sessionsDay${indexOfDay + 1}`}>{sectionContent}</div>
        )
      }
    }
    return agendaSections
  }

  const renderDayHeader = (day, index) => {
    return (
      <div key={`header-${index}`} className={generalStyles.flexColumn} data-testid={`headerDay${index + 1}`}>
        <button
          onClick={() =>
            setSectionVisibility((prevState) => ({
              ...prevState,
              [`section_${index}`]: !sectionVisibility[`section_${index}`]
            }))
          }
          className={classNames(
            generalStyles.flexRow,
            generalStyles.flexApart,
            generalStyles.p0,
            generalStyles.mt40
          )}
        >
          <span className={fontStyles.h1regulardark}>{day}</span>
          <div className={agendaStyles.iconContainer}>
            <IcomoonReact
              size={15}
              color={variables.evantaBlue}
              iconSet={iconSet}
              icon={
                sectionVisibility[`section_${index}`]
                  ? 'Control-CloseCarat-Large-BlueOutline'
                  : 'Control-OpenCarat-Large-BlueOutline'
              }
            />
          </div>
        </button>
        <div className={generalStyles.mt10}>
          <HorizontalLine color={'lightGrey'} fullWidth={true} />
        </div>
      </div>
    )
  }

  const renderTimeSectionHeader = (title, epochStart, index, inactive) => {
    return (
      <div
        key={`time-${epochStart}-${index}`}
        className={classNames(generalStyles.mt20, generalStyles.mb20, generalStyles.scrollMargin80)}
        id={`timeblock${index}`}
        data-testid={`timeblock${index}`}
      >
        <span
          className={classNames(fontStyles.h6bolddark, {
            [fontStyles.inactive]: inactive
          })}
        >
          {title}
        </span>
      </div>
    )
  }

  const renderAgendaItem = (item, index, section, i) => {
    if (item.itinerary) {
      return (
        <div key={`itin-${item.id}`} data-testid={`itinerary-${item.id}`}>
          <ItinerarySessionCard
            sessionType={item.name}
            topBorder={true}
            bottomBorder={true}
            inactive={false}
            sessionId={item.id}
            timeSectionHeaderIndex={i}
          />
        </div>
      )
    }
    let topBorder = true
    let bottomBorder = true
    let grayTopBorder = false
    if (section?.length > 1 && currentEvent.event_agenda_status_key === 'event_agenda_status_full_agenda') {
      if (index === 0) {
        topBorder = true
        bottomBorder = false
      } else if (index === section?.length - 1) {
        grayTopBorder = true
        topBorder = false
        bottomBorder = true
      } else {
        grayTopBorder = true
        topBorder = false
        bottomBorder = false
      }
    }
    return (
      <div key={`agenda-${item.id}`} data-testid={`session-${item.id}`} className={currentEvent.event_agenda_status_key !== 'event_agenda_status_full_agenda' ? generalStyles.mt20 : ''}>
        {grayTopBorder && <HorizontalLine color={'grey'} fullWidth={true} />}
        <div
          className={classNames(
            generalStyles.p0,
            agendaStyles.agendaItemContainer
          )}
        >
          <AgendaItemCard
            topBorder={topBorder}
            bottomBorder={bottomBorder}
            session={item}
            inactive={false}
            handleCardClick={handleClick}
            timeSectionHeaderIndex={i}
          />
        </div>
      </div>
    )
  }

  const showFilter = attendee && attendee?.is_approved

  const handleScrollToTime = () => {
    const currentTime = Math.floor(new Date().getTime() / 1000)
    const eventBeginDate = currentEvent.begin_date?.split('T')[0].split('-')
    const eventEndDate = currentEvent.end_date?.split('T')[0].split('-')
    // if the event is started and not ended
    if (eventBeginDate && eventEndDate) {
      if (
        new Date().setHours(0, 0, 0, 0) >=
        new Date(eventBeginDate[0], eventBeginDate[1] - 1, eventBeginDate[2]) &&
      new Date().setHours(0, 0, 0, 0) <=
        new Date(eventEndDate[0], eventEndDate[1] - 1, eventEndDate[2])
      ) {
        const sessionsInFuture = []
        for (const session of filterSessions(sessions)) {
        // look to all sessions, that have not ended, find the first and scroll to the top of that timeblock
          if (
            currentTime < session.epoch_end_time
          ) {
            sessionsInFuture.push(session)
          }
        }
        // find the nearest-in-time session that is in future
        const nextUpSession = sessionsInFuture.sort(
          (a, b) => a.epoch_start_time < b.epoch_start_time
        )[0]
        // find agenda item in DOM, 'session-#####'
        const nextUpSessionEl = document.getElementById(
          'session-' + nextUpSession?.id?.toString()
        )
        // find time section in DOM, 'timeblock#####'
        const nextUpTimeSectionEl = document.getElementById(
          'timeblock' + nextUpSessionEl?.getAttribute('data-time-section')?.toString()
        )

        // scroll to timesection header if not null
        if (nextUpTimeSectionEl !== null) {
          nextUpTimeSectionEl.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          })
        }
      }
    }
  }

  if (isLoadingEvent || isLoadingSessions) {
    return <div className={agendaStyles.loader}>
      <Loader />
    </div>
  }

  return (
    <div
      className={classNames(
        generalStyles.thirdWidth,
        generalStyles.flexColumn,
        agendaStyles.negMargin,
        {
          [generalStyles.pb50]: !isMobile,
          [generalStyles.pb75]: isMobile
        }
      )}
    >
      {showFilter && agendaAvailable && (
        <div
          onClick={resetSidePanelDetails}
          className={agendaStyles.agendaFiltersContainer}
        >
          <AgendaFilters
            initialFilters={initialFilters}
            filters={filters}
            onFilterChange={setFilters}
          />
        </div>
      )}
      {agendaAvailable ? renderAgendaSections() : renderComingSoon()}
    </div>
  )
}

export default AgendaList
