import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import IcomoonReact from 'icomoon-react'
import { isEmpty } from 'lodash'
import { useIsMobile } from 'src/hooks/useMediaQuery'
import { programContactOptions } from 'src/utils/helpers/DropdownOptionMethods'
import { useGlobalContext } from 'src/stores/GlobalContext'
import { useAnalyticsTracking } from 'src/utils/GoogleAnalytics'
import useFetchHomepageBlocks from 'src/queries/homepageBlocks/useFetchHomepageBlocks'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import variables from 'src/assets/styles/variables.module.scss'
import iconSet from 'src/assets/icons/iconList.json'
import UserRow from 'src/components/Elements/UserRow'
import HorizontalLine from 'src/components/Elements/HorizontalLine'
import EventCard from 'src/features/programs/home/components/EventCard'
import Dropdown from 'src/components/Elements/Dropdown'
import CarouselCard from 'src/features/programs/program/home/components/CarouselCard'
import ImageWithText from 'src/features/programs/program/home/components/ImageWithText'
import LocationBlock from 'src/features/programs/program/home/components/LocationBlock'
import Button from 'src/components/Elements/Button'
import ProgramHomeSkeleton from 'src/features/programs/program/home/ProgramHomeSkeleton'
import programStyles from './programStyles.module.scss'
import { dayBeforeEventHasStarted } from 'src/utils/helpers/EventHelperMethods'

const ProgramHomePage = () => {
  const { currentEvent, isLoadingEvent, setSidePanelDetails, isLoadingSessions, currentUser } = useGlobalContext()
  const { homepageBlocks, isLoadingHomepageBlocks } = useFetchHomepageBlocks()
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const googleTracking = useAnalyticsTracking()

  if ((isLoadingEvent && isLoadingSessions && isLoadingHomepageBlocks) || isEmpty(currentEvent)) {
    return <ProgramHomeSkeleton />
  }

  const clickEvent = (block) => {
    const { id, subtitle } = block
    setSidePanelDetails({ type: 'homepage_block', data: id, title: subtitle || '' })
    if (block.type_key.includes('research')) {
      googleTracking('gartner_research_view', 'event_page')
    }
  }

  const setBlocks = () => {
    const blockContainers = []
    for (const block of homepageBlocks) {
      switch (block.type_key) {
        case 'themes': {
          if (block.website_themes?.length !== 0) {
            blockContainers.push(
              <div
                key={`themes-${block.id}`}
                className={classNames(generalStyles.pt20, generalStyles.pb20)}
                data-testid={`themesBlock${block.id}`}
              >
                <span className={fontStyles.h2alternate2bolddark} data-testid={`themesBody${block.id}`}>
                  {block.text_body}
                </span>
                <div
                  className={classNames(generalStyles.mt10, generalStyles.mb10)}
                >
                  <HorizontalLine color='lightGrey' fullWidth />
                </div>
                {block.website_themes.map((theme, i) => {
                  return (
                    <div
                      key={`theme${i}`}
                      className={classNames(
                        generalStyles.flexRow,
                        generalStyles.alignVertical,
                        generalStyles.pt10,
                        generalStyles.pb10
                      )}
                      data-testid={`singleTheme${i + 1}`}
                    >
                      <span className={fontStyles.h3boldblue} data-testid={`themeNumber${(i += 1)}`}>{(i)}</span>
                      <div className={generalStyles.ml10}>
                        <span className={fontStyles.h3alternate2regulardark} data-testid={`themeBody${(i += 1)}`}>
                          {theme}
                        </span>
                      </div>
                    </div>
                  )
                })}
              </div>
            )
          }
          break
        }
        case 'image_with_text_and_html':
        case 'gartner_research': {
          blockContainers.push(
            <div
              key={`hns-${block.id}`}
              className={classNames(generalStyles.pt20, generalStyles.pb20)}
            >
              <ImageWithText
                blockType={block?.type_key}
                key={block?.id}
                overlayText={block?.title}
                linkText={block?.text_body}
                clickEvent={() => clickEvent(block)}
                imageUrl={block?.image}
              />
            </div>
          )
          break
        }
        case 'location': {
          const locationDetails = block?.location_details
          if (
            ![null, ''].includes(locationDetails.venue_name) &&
            ![null, ''].includes(locationDetails.venue_url) &&
            ![null, ''].includes(locationDetails.map_source_code)
          ) {
            blockContainers.push(
              <div
                key={`location-${block.id}`}
                className={classNames(generalStyles.pb20, generalStyles.pt20)}
              >
                <LocationBlock
                  block={block}
                  venueName={locationDetails.venue_name}
                  venueUrl={locationDetails.venue_url}
                  mapUrl={locationDetails.map_source_code}
                  venueInfo={locationDetails.information}
                  reservationInfo={locationDetails.reservations_blurb}
                  reservationUrl={locationDetails.online_booking_url}
                />
              </div>
            )
          }
          break
        }
        case 'image': {
          if (block.image && currentEvent.is_attended && dayBeforeEventHasStarted(currentEvent)) {
            blockContainers.push(
              <div
                key={`wifi-${block.id}`}
                className={classNames(generalStyles.pt20, generalStyles.pb20)}
              >
                <img
                  src={block.image}
                  alt={block.text}
                  className={programStyles.wifiImage}
                />
              </div>
            )
          }
          break
        }
        case 'carousel': {
          const carouselDetails = block.carousel_details
          if (carouselDetails.slide_1_image) {
            const slides = [1, 2, 3].map((num) => {
              return {
                key: carouselDetails[`slide_${num}_title`]
                  ?.toLowerCase()
                  ?.replace(' ', '-'),
                title: carouselDetails[`slide_${num}_title`],
                imageUrl: carouselDetails[`slide_${num}_image`],
                body: carouselDetails[`slide_${num}_body`]
              }
            })
            const carouselCards = slides.map((slide) => {
              if (slide?.title && slide?.imageUrl && slide?.body) {
                return (
                  <CarouselCard
                    key={slide.key}
                    carouselKey={slide.key}
                    imageUrl={slide.imageUrl}
                    blockTitle={block?.title}
                    slideTitle={slide?.title}
                    slideBody={slide.body}
                  />
                )
              }
            })
            blockContainers.push(
              <div
                key={`expect-${block.id}`}
                className={classNames(generalStyles.pt20, generalStyles.pb20)}
                data-testid={`expectBlock${block.id}`}
              >
                {carouselCards}
              </div>
            )
          }
          break
        }
        default:
          blockContainers.push(null)
      }
    }
    return blockContainers
  }

  return (
    <div className={generalStyles.flexColumn}>
      <div
        className={classNames(generalStyles.pb40, generalStyles.medWidth, {
          [generalStyles.mr20]: !isMobile
        })}
      >
        <EventCard
          event={currentEvent}
          eventCardType={currentEvent.is_virtual_event ? 'Virtual' : 'In-person'}
          currentEvent={true}
          largerHeight={true}
        />
      </div>
      <div>
        {currentEvent?.is_approved && (
          <div className={generalStyles.mb20}>
            <span className={fontStyles.h1bolddark}>
              Welcome, {currentUser?.first_name}
            </span>
            <div className={generalStyles.mt10}>
              <HorizontalLine color='lightGrey' fullWidth/>
            </div>
          </div>
        )}
      </div>
      <div className={classNames(generalStyles.smallWidth, generalStyles.pb50)}>
        <> { isEmpty(homepageBlocks) && !isLoadingHomepageBlocks
          ? <div className={classNames(
            generalStyles.pt20,
            generalStyles.pb20
          )}>Details coming soon</div>
          : setBlocks()}</>
        {currentEvent.event_website_users?.length > 0 && (
          <div key='programContacts' className={generalStyles.pt20} data-testid='pmContacts'>
            <span className={fontStyles.h3alternate2bolddark} data-testid='pmContactsTitle'>
              Program Contacts
            </span>
            <div className={classNames(generalStyles.mt10)}>
              <HorizontalLine color={'lightGrey'} fullWidth={true} />
            </div>
            {currentEvent.event_website_users.map((u, i) => {
              return (
                <div key={u.id} data-testid={`contact-${u.id}`}>
                  <div
                    className={classNames(
                      generalStyles.flexRow,
                      generalStyles.flexApart,
                      generalStyles.pt20,
                      generalStyles.pb20
                    )}
                  >
                    <UserRow user={u} />
                    <Dropdown
                      iconName='Control-EllipsisMenuIcon-Small-Grey'
                      iconSize={16}
                      iconColor='grey'
                      position='left'
                      items={programContactOptions(u)}
                    />
                  </div>
                  <HorizontalLine
                    color='lightGrey'
                    fullWidth={currentEvent.event_website_users?.length === i + 1}
                  />
                </div>
              )
            })}
          </div>
        )}
        <div className={programStyles.contactUsBlock}>
          <div
            className={classNames(
              generalStyles.flexRow,
              generalStyles.alignVertical
            )}
          >
            <div className={generalStyles.mr20} data-testid='supportIcon'>
              <IcomoonReact
                icon='Symbol-EmailIcon-Large-BlueOutline'
                iconSet={iconSet}
                color='evantaBlue'
                size={20}
                data-testid='Symbol-EmailIcon-Large-BlueOutline'
              />
            </div>

            <span className={fontStyles.h3alternate2bolddark} data-testid='supportTitle'>
              Get in Touch
            </span>
          </div>
          <div className={classNames(generalStyles.mt10, generalStyles.mb20)}>
            <HorizontalLine fullWidth color='white' />
          </div>
          <span data-testid="supportBody" className={fontStyles.bodyregulardark}>
            Need help? Reach out to our Help & Support team below.
          </span>
          <div
            className={classNames(
              generalStyles.mt20,
              generalStyles.flexCentered
            )}
          >
            <Button
              label='Contact Us'
              size='med'
              color='evantaBlue'
              onClick={() => navigate(`/programs/${currentEvent.id}/contact`)}
            />
          </div>
        </div>

        <div
          className={classNames(
            generalStyles.mt20,
            generalStyles.mb30,
            generalStyles.flexCentered
          )}
          data-testid='refreshDataRow'
        >
          <button
            onClick={() => location.reload()}
            className={classNames(
              generalStyles.hoverGrayBtn,
              generalStyles.flexRow,
              generalStyles.alignVertical
            )}
          >
            <IcomoonReact
              icon='Symbol-RefreshIcon-Medium-GreyOutline'
              iconSet={iconSet}
              color={variables.evantaBlue}
              size={15}
              className={generalStyles.mr20}
              data-testid='Symbol-RefreshIcon-Medium-GreyOutline'
            />
            <span className={fontStyles.h6demigrey} data-testid='refreshDataBody'>
              Refresh All Event Data
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProgramHomePage
