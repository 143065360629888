import React, { useEffect, useRef } from 'react'
import { useLocation, Outlet } from 'react-router-dom'
import classNames from 'classnames'
import { useIsMobile, useIsTabletOrMobile } from 'src/hooks/useMediaQuery'
import { useGlobalContext } from 'src/stores/GlobalContext'
import { checkPathPresent } from 'src/utils/helpers/GeneralHelpers'
import ProgramsNavigation from 'src/features/navigation/ProgramsNavigation'
import BackNavigation from 'src/features/navigation/BackNavigation'
import layoutStyles from './layouts.module.scss'
import RightSidePanel from './SidePanels/RightSidePanel'
import FlashMessage from '../Elements/FlashMessage'

const RightPanelLayout = ({ isP2P }) => {
  const { eventId, attendeeId, partnerId, evalId, sessionId, sidePanelDetails } = useGlobalContext()
  const { pathname } = useLocation()
  const contentRef = useRef()
  const isMobile = useIsMobile()
  const isTabletOrMobile = useIsTabletOrMobile()
  const pathsWithBackOnlyNav = ['leaderboard-ranking', 'contact', 'edit-', 'scanner', 'qr-code']
  const scannerPaths = ['scanner', 'qr-code']
  /* eventId = programs route */
  const navigationPresent = eventId || ''
  const scrollToTop = () => {
    contentRef.current?.scrollIntoView()
  }
  useEffect(() => {
    if (!sessionId) {
      scrollToTop()
    }
  }, [pathname])

  return (
    <div className={layoutStyles.rightPanelContainer}>
      <div className={layoutStyles.contentContainer}>
        {(navigationPresent || pathname.includes('edit-')) && (
          <>
            {attendeeId ||
            partnerId ||
            evalId ||
            checkPathPresent(pathsWithBackOnlyNav, pathname)
              ? (
                <BackNavigation
                  showScannerButtons={
                    checkPathPresent(scannerPaths, pathname)
                  }
                />
              )
              : (
                <ProgramsNavigation isMobile={isMobile} />
              )}
          </>
        )}

        <FlashMessage />
        <div
          id="scrollable-container"
          className={classNames(
            isP2P ? layoutStyles.p2pContainer : layoutStyles.scrollableContainer
          )}
        >
          <div ref={contentRef} />
          <div
            className={classNames(layoutStyles.content, {
              [layoutStyles.scrollableWithNav]:
                navigationPresent || pathname.includes('edit-'),
              [layoutStyles.scrollableWithFilters]: [
                'agenda',
                'attendees',
                'governing-body'
              ].find((v) => pathname.includes(v))
            })}
          >
            <Outlet />
          </div>
        </div>
      </div>
      {!isP2P && (
        <div
          className={classNames(layoutStyles.sidePanelContainer, {
            [layoutStyles.tabletSidePanel]: isTabletOrMobile || isMobile,
            [layoutStyles.hidden]: !sidePanelDetails.type
          })}
        >
          <RightSidePanel />
        </div>
      )}
    </div>
  )
}

export default RightPanelLayout
