import { deviceDetect } from 'react-device-detect'
const agent = deviceDetect(window?.navigator?.userAgent)
const device = agent?.osName?.concat(' ')?.concat(agent?.osVersion)
const browser = agent?.browserName
  ?.concat(' ')
  ?.concat(agent?.browserFullVersion)
export const deviceTextTemplate = `

    --------------------------
    Device Details for Support
    Device: ${device} | Browser: ${browser}
    `
