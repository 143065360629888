import React from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import IcomoonReact from 'icomoon-react'
import { useIsMobile } from 'src/hooks/useMediaQuery'
import { approvedStatuses, dayBeforeEventHasStarted, isOneDayPastEvent } from 'src/utils/helpers/EventHelperMethods'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import variables from 'src/assets/styles/variables.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import iconSet from 'src/assets/icons/iconList.json'
// import Badge from 'src/src/components/Elements/Badge'
import EventRegButton from './EventRegButton'
import EventTypeChip from './EventTypeChip'
import programStyles from '../programStyles.module.scss'
import QrButton from './QrButton'

// eventCardType: String, Up Next, Virtual, In-Person
// currentEvent: Boolean, optional
// largerHeight: Boolean, optional
const EventCard = ({ event, eventCardType, currentEvent, largerHeight }) => {
  const navigate = useNavigate()
  const handleViewEvent = (e) => {
    e.preventDefault()
    if (event?.is_virtual_event && event?.is_approved) {
      return window.open(event?.virtual_portal_url, '_blank')
    } else {
      return navigate(`/programs/${event?.id}`)
    }
  }
  const hasNotifications = event?.has_notifications && approvedStatuses(event)
  const isMobile = useIsMobile()

  return (
    <div
      className={
        classNames(programStyles.eventCardContainer, {
          [programStyles.largerHeight]: largerHeight,
          [programStyles.notClickable]: currentEvent
        })}
      style={{
        backgroundImage: `url(${event?.background_image_web}), url(${event?.fallback_background_image_web})`,
        cursor: 'default'
      }}
    >
      <div
        className={classNames(generalStyles.flexColumn, generalStyles.fullFlex)}
        data-testid="eventCard"
        onClick={(e) => handleViewEvent(e)}
        style={{ cursor: 'pointer' }}
      >
        <div
          className={classNames(
            generalStyles.flexRow,
            generalStyles.alignVertical
          )}
        >
          <EventTypeChip eventCardType={eventCardType} color={'white'} />
          {!isMobile && (
            <span className={fontStyles.h6demilight} data-testid="eventDate">
              {event?.formatted_date_range}
            </span>
          )}
          {!currentEvent && hasNotifications && (
            <div className={programStyles.alertContainer} data-testid="eventNotice">
              {/* <Badge number={3} />  */}
              <IcomoonReact
                iconSet={iconSet}
                color={variables.white}
                size={24}
                icon="Symbol-AlertIcon-Circle-Red"
              />
            </div>
          )}
        </div>
        {isMobile && (
          <div
            className={classNames(
              generalStyles.flexRow,
              generalStyles.alignVertical,
              generalStyles.mt20
            )}
          >
            <span className={fontStyles.h6demilight}>
              {event?.formatted_date_range}
            </span>
          </div>
        )}
        <div
          className={classNames(
            programStyles.eventName,
            generalStyles.flexColumn
          )}
        >
          <span
            className={classNames(
              fontStyles.h2alternate2boldlight,
              generalStyles.mb10
            )}
            data-testid="eventName"
          >
            {event?.name}
          </span>
          {currentEvent && (
            <span className={fontStyles.h6variant2demilight} data-testid="eventDetails">
              {event?.is_virtual_event
                ? event?.formatted_time + ' ' + event?.timezone_abbreviation
                : event?.venue_name}
            </span>
          )}
        </div>
      </div>
      <div
        className={classNames(generalStyles.flexRow, generalStyles.flexApart)}
      >
        { currentEvent && approvedStatuses(event) && dayBeforeEventHasStarted(event) && !event?.is_virtual_event
          ? <QrButton event={event} />
          // if event has passed, show no reg CTA
          // Do not remove CTA if event card is 'Up Next' type
          : isOneDayPastEvent(event) && eventCardType !== 'Up Next'
            ? ''
            : <EventRegButton
              event={event}
              eventCardType={eventCardType}
              handleViewEvent={handleViewEvent}
              currentEvent={currentEvent}
            />
        }
        <div className={programStyles.eventActionContainer}>
          {/* Not for MVP */}
          {/* <IcomoonReact
            iconSet={iconSet}
            color={variables.white}
            size={24}
            icon="Control-EllipsisMenuIcon-Large-WhiteSolid"
          /> */}
        </div>
      </div>
    </div>
  )
}

export default EventCard
